@use '../config';

[data-layout='root'] {
  // image, size, position, & media-queries are all related *magically*
  // and should be adjusted/tested as a group by hand
  @supports (background-blend-mode: multiply) {
    background-blend-mode: var(--bg-blend, multiply);
    background-image:
      url('/assets/media/header-wash.webp'),
      url('/assets/media/footer-wash.webp');
    background-position:
      var(--header-pos, left -8em) top 0,
      bottom left;
    background-repeat: no-repeat, no-repeat;
    background-size:
      auto 5.5em,
      20em auto;
  }

  @include config.above(40em) {
    --header-pos: right 0;
  }

  @include config.above(50em) {
    --header-pos: left 0;
  }

  @media (prefers-color-scheme: dark) {
    &:not([style*='--ccs-mode--user']) {
      --bg-blend: color-dodge;
    }
  }
}

[style*='--ccs-mode--user:-1'] {
  --bg-blend: color-dodge;
}

html {
  overflow-x: clip;
}

html,
body {
  min-block-size: 100%;
}

body {
  display: grid;
  grid-template:
    'alert' auto
    'banner' auto
    'main' 1fr
    'cta' auto
    'footer' auto
    / 100%;
}
