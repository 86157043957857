@use '../config';
@include config.import-webfonts;

html {
  @include config.font-family('body');
  @include config.ratios--;
  @include config.times--;
  @include config.changes--;

  --clip-overlap: var(--page-margin);
  --clip-angle: var(--double-gutter);
  --clip-space: calc(var(--clip-angle) + var(--gutter));
  --clip-left: polygon(var(--clip-angle) 0, 100% 0, 100% 100%, 0 100%);
  --clip-right: polygon(
    0 0,
    100% 0,
    calc(100% - var(--clip-angle)) 100%,
    0 100%
  );

  font-size: var(--root);
  line-height: var(--line-height);
}
