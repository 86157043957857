// SVG Charts
// ==========

[data-size='chart'] {
  height: 20rem;
  margin-bottom: 2rem;
  padding: 1rem;
  width: 100%;
}

.chart-line {
  fill: none;
  stroke: var(--border);
  stroke-width: 2;
}

.chart-highlight {
  fill: var(--ccs-special--fg1);
  stroke: var(--ccs-special--fg1);
}

.chart-text {
  font-size: 0.65rem;
}

.chart-dashed {
  fill: none;
  stroke: var(--ccs-special--fg1);
  stroke-dasharray: 16, 15;
  stroke-miterlimit: 10;
  stroke-width: 5;
}
