@use 'pkg:accoutrement' as tools;

.anchor-link-wrapper {
  display: inline-block;
  position: relative;

  h2 {
    display: inline-block;
    padding-left: var(--anchor-link-padding-l, var(--gutter));

    @include tools.above('wide-page') {
      --anchor-link-padding-l: 0;
    }
  }
}

.header-anchor {
  --left-position-offset-default: -0.25em;
  --top-position-offset-default: var(--gutter-plus);

  left: var(--anchor-position-offset-l, var(--left-position-offset-default));
  position: absolute;
  top: var(--top-position-offset-default);
  transform-origin: top center;
  width: var(--gutter);

  @include tools.above('wide-page') {
    --anchor-position-offset-l: -1em;
  }

  svg {
    stroke: var(--fill, currentColor);
    stroke-width: 1.25;
    transition: tools.change('move');
  }

  &:visited {
    --fill: var(--ccs-accent--bg1-fade);
    --action: var(--ccs-accent--bg1-fade);
  }

  &:hover,
  &:focus,
  &:active {
    --fill: var(--ccs-accent--fg2);
  }
}

.anchor-clicked {
  &::before {
    @include tools.animate('fadein-out');

    background: var(--feature);
    border-radius: var(--radius);
    color: var(--bg);
    content: 'Link Copied';
    font-size: var(--xxsmall);
    left: var(--left-position-offset-default);
    opacity: 0;
    padding: var(--quarter-shim) var(--half-shim);
    position: absolute;
    top: calc(var(--top-position-offset-default) * -1);
    white-space: nowrap;
  }

  svg {
    transform: rotate(-45deg);
  }
}
