@use '../config/layout';

blockquote {
  --edge: minmax(var(--page-margin), 1fr);
  --quote: minmax(min-content, var(--page));

  background-color: var(--callout);
  display: grid;
  margin: var(--newline) 0;
  padding: var(--spacer) 0;

  &:nth-of-type(odd) {
    grid: 'edge icon quote' auto / var(--edge) auto var(--quote);

    @include layout.clip('right');
  }

  &:nth-of-type(even) {
    grid: 'icon quote edge' auto / auto var(--quote) var(--edge);

    @include layout.clip('left');
  }

  .u-photo {
    border-radius: 100%;
  }

  .p-name {
    font-style: italic;
  }

  #intro > & {
    margin-inline-start: calc(50% - 50vw);
  }

  &:not([data-quote]) {
    &::before {
      color: var(--icon);
      content: '“';
      font-size: 4em;
      grid-column: icon;
      margin: -0.25em var(--gutter) -2em;
    }

    > * {
      grid-column: quote;
    }

    > p:last-child:not(:first-child) {
      --newline: 0;

      font-size: var(--small);
    }
  }
}

// Quote Pattern
// -------------

[data-quote='solo'],
blockquote:not([data-quote]) {
  &:nth-of-type(odd) {
    --column: full / wide;

    margin-left: 0;
    padding-left: var(--page-margin);
  }

  &:nth-of-type(even) {
    --column: wide / full;

    margin-right: 0;
    padding-right: var(--page-margin);
  }
}

[data-media='quote'] {
  --fill: var(--icon);
  --media-size: var(--double-gutter);
  --media-min: 3em;
  --media-ratio: 1;

  grid-area: quote;
}

// Signature
// ---------

.signature {
  display: grid;
  grid:
    [sig-start] auto [by-start] var(--gutter-plus) [sig-end] auto [by-end]
    / [sig-start] 3em [by-start] max-content [by-end sig-end];
  justify-content: end;
  transform: rotate(calc(var(--rotate) * -1));

  [data-img] {
    grid-area: sig;
    height: 4em;
    width: auto;
  }

  .byline {
    grid-area: by;
  }
}
