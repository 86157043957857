@use '../config';

figure {
  --column: wide;

  margin: var(--newline) 0;
}

.ratio {
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    height: 0;
    padding-top: calc(100% / var(--ratio));
    width: 1px;
  }

  > * {
    @include config.square(100%);

    inset: 0;
    position: absolute;
  }
}

.gallery {
  display: grid;
  grid-gap: var(--shim);
  grid-template-columns:
    [full-start] repeat(auto-fit, minmax(40%, 1fr))
    [full-end];

  img,
  svg {
    width: 100%;
  }

  iframe,
  canvas,
  video,
  audio {
    margin-bottom: 0;
  }

  [style] {
    align-self: var(--align);
    grid-column: var(--span, initial);
    width: var(--width, 100%);
  }

  // Override vendor max width
  lite-youtube {
    max-width: 100%;
  }
}

figcaption,
.caption {
  font-size: var(--small);
  font-style: italic;
  margin-top: var(--shim);

  .extend-full & {
    padding-inline: var(--page-margin);
  }
}

// codepen embeds
.cp_embed_wrapper {
  --column: wide;
}
