.logo-grid {
  --column: content;
  --fill: var(--feature);

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: var(--spacer) 0 var(--gutter);

  > * {
    flex: var(--logo-flex);
    margin: var(--double-gutter);
    width: var(--logo-item);
  }

  > a {
    --fill: currentcolor;
  }
}
