[face-pile] {
  display: flex;
  flex-wrap: wrap;
  gap: var(--shim);

  > * {
    border: thin solid;
    border-radius: 100%;
    flex: none;
    overflow: clip;
  }

  img {
    aspect-ratio: 1;
    block-size: var(--face-size, 2lh);
    inline-size: auto;
    max-width: unset;
  }

  [data-supporters-face='md'] {
    --face-size: 3lh;
  }

  [data-supporters-face='lg'] {
    --face-size: 4lh;
  }
}
