@use 'sass:list';

[data-ccs='menu'] {
  display: none;

  @supports (--custom: props) {
    display: flex;
  }
}

$rainbow: ();

@for $hue from 1 through 36 {
  $rainbow: list.append($rainbow, hsl($hue * 10, 80%, 40%), comma);
}

[data-ccs-input='hue'] {
  background-image: linear-gradient(to right, $rainbow);
}

[data-ccs-input='lightness'] {
  background-image: linear-gradient(to right, hsl(0deg, 0%, 25%), silver);
}

[data-ccs-input='saturation'] {
  // 0.1% so that units aren't removed in minification
  background-image: linear-gradient(
    to right,
    hsl(var(--ccs-h--prime, 0), 0.1%, var(--ccs-l, 50%)),
    hsl(var(--ccs-h--prime, 0), 100%, var(--ccs-l, 50%))
  );
}

[data-ccs-input='contrast'] {
  background-image:
    linear-gradient(to right, gray, white),
    linear-gradient(to right, gray, black);
  background-size:
    100% 50%,
    100% 100%;
  background-position:
    top left,
    bottom left;
}
