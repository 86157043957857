#hero {
  --fill: var(--feature);

  grid-area: hero / hero / hero2 / hero2;
  position: relative;

  &::before {
    box-shadow: 0 0 var(--quarter-shim) var(--shadow) inset;
    content: '';
    inset: 0 -1em;
    pointer-events: none;
    position: absolute;
    z-index: 1;
  }
}
