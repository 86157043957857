@use '../config';

#title {
  @include config.z-index('bump');

  --blur: var(--quarter-shim);
  --outset: calc(var(--blur) * -1);

  align-self: center;
  grid-area: title;
  position: relative;
  margin: 0;

  #hero + & {
    // clip the horizontal and bottom shadows only
    clip-path: inset(var(--outset) calc(var(--outset) / 1.5) 0 0);
    filter: drop-shadow(0 0 var(--blur) var(--shadow));
    padding-right: calc(var(--angle) + var(--gutter));

    // create a clipped background shape
    &::before {
      background-color: var(--bg);
      inset: 0 var(--outset) var(--outset) var(--outset);
      clip-path: polygon(
        0 0,
        calc(100% - var(--angle)) 0,
        calc(100% + var(--outset)) var(--clip-overlap),
        100% var(--clip-overlap),
        100% 100%,
        0 100%
      );
      content: '';
      position: absolute;
      z-index: -1;
    }
  }

  // We Create
  [data-header='angle'] & {
    line-height: 1.1;

    span {
      // spans not generated by the typogrify plugin
      &:not([class]) {
        display: block;
        font-size: var(--h3);
        font-weight: normal;
      }
    }
  }
}
