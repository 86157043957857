[inline-list~='pill-set'] {
  --item-display: inline-block;
}

.pill-item {
  font-size: var(--xsmall);
  margin: var(--half-shim);
}

.pill {
  &:link,
  &:visited {
    background-color: hsla(
      var(--ccs-h--special),
      var(--ccs-s--bg1),
      var(--ccs-l--bg5),
      var(--ccs-fade)
    );
    border: thin solid var(--ccs-special--bg2-fade);
    border-radius: var(--radius);
    display: inline-block;
    padding: 0 var(--shim);
    text-decoration: none;
  }

  &:hover,
  &:focus {
    border-color: var(--ccs-special);
  }
}
