@use '../config';

/// # Typography
/// Basic typographic styles for the site.
/// @group type

// Lists
// -----
/// Apply spacing after lists.
/// @group type
ul,
ol {
  margin-bottom: var(--newline);
  margin-top: 0;
  padding-left: var(--list-padding, var(--list-padding--default, 0));

  & & {
    --list-padding--default: 1em;
  }

  @include config.below('wide-page') {
    --list-padding--default: 1em;
  }
}

// List Markers
// ------------
/// Lighter markers and smaller bullet points…
/// @group type
::marker {
  color: var(--marker-color, var(--text-light));
  font-size: var(--marker-size, 1em);

  ul & {
    --marker-size: 0.75em;
  }
}

// Fancy Lists
// -----------
/// Larger markers w/spaced out list items like on bottom of design/dev services page
/// @group type
[data-block='fancy-list'] {
  ::marker {
    --marker-color: var(--icon);
    --marker-size: var(--h2);
  }

  li {
    padding-bottom: var(--newline);
  }

  strong {
    @include config.font-family('alt');

    font-size: var(--h3);
  }
}

// Inline Lists
[inline-list] {
  display: inline;
  list-style: none;
  padding: 0;

  li {
    display: var(--item-display, inline-block);
    margin-right: var(--item-space, 0);

    &::before {
      content: '\200B';
      position: absolute;
    }

    &::after {
      content: var(--item-after, none);
    }
  }
}

[inline-list~='comma'] {
  li:not(:last-child) {
    --item-space: 0.4em;
    --item-after: ', ';
  }
}
