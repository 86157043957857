[data-screen] {
  position: relative;
  overflow: hidden;
}

[data-screen='iphone'] {
  --device-margin: 5%;
  --device-margin-top: var(--device-img-notch, 15%);
  --device-radius: var(--double-gutter);

  img {
    background: var(--device-img-fill, white);
    clip-path: inset(
      var(--device-clip-inset, 5px) round var(--device-radius, 0)
    );
    height: 100%;
    inset: 1px;
    object-fit: var(--device-img-fit, cover);
    object-position: var(--device-img-position, top);
    overflow: hidden;
    padding: var(--device-margin-top, var(--device-margin))
      var(--device-margin-right, var(--device-margin))
      var(--device-margin-bottom, var(--device-margin))
      var(--device-margin-left, var(--device-margin));
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  .oddnews-intro & {
    --device-radius: calc(0.3rem * 2);
  }
}

[data-device] {
  fill: var(--bg);
  pointer-events: none;
  stroke: var(--border);
  stroke-width: 2;
  width: 100%;
}
