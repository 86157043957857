@use '../config';

/// # Accessibility
/// Helpers and utilities formaking the site more accessible.
/// @group a11y

// Hidden Everywhere
// -----------------
/// The `hidden` html attribute
/// hides content from both display and screen readers.
/// @group a11y
[hidden] {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

// Screen Reader Only
// ------------------
/// A class to hide content visually,
/// while remaining visible to screen readers.
/// Interactive elements will also become visble
/// when focussed or active.
/// @group a11y
.sr-only {
  &:not(:focus, :active) {
    @include config.is-hidden;
  }
}

// Skip to Content
// ---------------
/// The "skip to content" link is hidden by default,
/// but appears first for screen readers,
/// and first in the tab-order --
/// allowing keyboard & nonvisual users to skip
/// over the repeated site header
/// (home link, menu, and navigation).
/// @group a11y
#to-content {
  &:link,
  &:visited {
    @include config.z-index('skip-link');

    --underline-color: currentcolor;

    background: var(--active);
    color: var(--bg);
    opacity: 0;
    padding: var(--shim) var(--gutter);
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate3d(0, -100%, 0);
    transition: var(--move), var(--fade);
  }

  &:focus,
  &:active {
    opacity: 1;
    pointer-events: initial;
    transform: translate3d(0, 0, 0);
  }
}

// Focusable Main Title
// --------------------
/// The main content title has a `tabindex="-1"`
/// so that it does not appear in the tab order --
/// but it can recieve focus from the
/// @group a11y
#title {
  &:focus {
    outline: none;
  }
}
