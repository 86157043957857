@use '../config';

[data-table] {
  border-collapse: collapse;
  margin: var(--newline) 0;
  width: 100%;

  th {
    @include config.font-family('alt');

    font-weight: bold;
  }

  th,
  td {
    border: thin solid var(--cell-border, var(--border));
    padding: var(--cell-padding, var(--half-shim));
    font-style: inherit;
    text-align: left;

    @include config.above('page') {
      --cell-padding: var(--shim);
    }
  }
}

thead {
  font-size: var(--thead-size, var(--xxsmall));

  @include config.above('page') {
    --thead-size: var(--small);
  }
}

tbody {
  font-size: var(--tbody-size, var(--xsmall));

  @include config.above('page') {
    --tbody-size: var(--small);
  }
}
