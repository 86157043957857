.size-quarter {
  width: 25%;
}

// Extend thing past the width of its container on both sides
.extend-small {
  --column: wide;

  width: 100%;
}

.extend-large {
  --column: content;

  width: 100%;
}

.extend-full {
  --column: full;

  width: 100%;
}
