/// # SVG Icons
/// @icons content/_includes/icons/
/// @group icons

// Icon
// ----
/// By default, icons take on the size of surrounding text.
/// @group icons
[data-icon] {
  display: inline-block;
  height: var(--icon-height, var(--icon-size, 1em));
  transform: translateY(-0.1em);
  overflow: visible;
  width: var(--icon-width, var(--icon-size, 1em));
}

// shopify logo
// [data-shopify='light'] » #95bf47
// [data-shopify='shadow'] » #5e8e3e
// [data-shopify='cutout'] » #fff
// [data-shopify='wordmark'] » #000
[data-shopify='shadow'] {
  fill: var(--shadow);
}

[data-shopify='cutout'] {
  fill: var(--bg);
}
