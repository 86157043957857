[data-layout='nav'] {
  font-size: var(--small);
  grid-column: logo / nav;
  grid-row: nav;
}

[data-navlist] {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: var(--shim);
  padding: 0;
}

[data-navlist='main'] {
  margin-right: calc(var(--banner-space) * -1);

  // this keeps the first line from overlapping with the logo tail
  &::before {
    content: '';
    width: 6em;
  }
}

[data-nav] {
  display: inline-block;
  padding: var(--shim) var(--banner-space);

  &:not(:hover, :active, :focus, [aria-current]) {
    --underline-color: transparent;
    --underline-thickness: 0;
  }
}

[aria-current='wide-page'] {
  --link: var(--active);
  --link-focus: var(--link);
  --underline-color: var(--link);
}
