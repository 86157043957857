[data-fieldgroup='contact-info'] {
  display: grid;
  grid-column-gap: var(--gutter);
  grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
}

.social-list {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--small);
  margin: var(--newline) 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0 var(--gutter) var(--shim) 0;
  }

  #meta & {
    margin-top: 0;
  }
}
