@use 'sass:math';
@use '../config';

/// # CSS Size Variables
/// Convert useful [Sass scale tokens](config-scale.html)
/// into global CSS variables.
/// @group init-scale

/// Generate variables from Sass maps,
/// using Accoutrement tools.
/// @name Global Sizes
/// @link https://www.oddbird.net/accoutrement/docs/token-vars
///   Accoutrement CSS Variables
/// @group init-scale
html {
  @include config.sizes--;

  --page-columns: [full-start] var(--page-margin) [content-start wide-start
    page-start] minmax(0, var(--page)) [page-end wide-end content-end]
    var(--page-margin) [full-end];

  @include config.above('wide-page') {
    --page-columns: [full-start] var(--page-margin) [content-start]
      minmax(0, 1fr) [wide-start] minmax(0, var(--extra)) [page-start]
      minmax(var(--min-page-column), var(--page)) [page-end]
      minmax(0, var(--extra)) [wide-end] minmax(0, 1fr) [content-end]
      var(--page-margin) [full-end];
  }
}
