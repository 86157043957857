#intro {
  --newline: var(--newline--default);

  grid-area: intro;

  [data-header='hero'] & {
    font-size: var(--medium);
    justify-self: center;
    max-width: var(--wide-page);
    margin: var(--spacer) var(--page-margin);
    width: calc(100% - var(--page-margin) * 2);
  }
}

.summary {
  > :last-child {
    --newline: 0;
  }
}

[data-media='bird-intro'] {
  --align: center;
  --media-max: var(--logo-item);
}
