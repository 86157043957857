@use '../config';

[data-grid='open-source'] {
  --col-gap: var(--spacer);
  --fit-max: var(--min-page-column);
  --item-title: var(--h2);

  header {
    --newline: var(--shim);
  }

  .item-title {
    --link: unset;
  }

  [data-logo] {
    height: calc(var(--gutter) * 5);
    width: auto;
    max-width: 75%;
  }
}

.donate-link {
  justify-self: start;

  img {
    inline-size: auto;
    max-block-size: calc(1lh + var(--gutter));
  }
}

[data-grid~='featured-goal'] {
  --column: wide;
  --goal-column: minmax(25ch, 1fr);

  grid-template:
    'hero' auto
    'header' auto
    'summary' auto
    'list' auto
    'action' min-content / 100%;

  @include config.above('wide-page') {
    grid-template:
      'hero hero hero hero' auto
      '... header header ...' auto
      '... summary list ... ' auto
      '... action action ... ' min-content / var(--gutter) var(--goal-column)
      var(--goal-column) var(--gutter);
  }

  .main-action {
    --main-action-margin: 0;

    font-size: var(--h4);
    grid-area: action;
  }
}

.goal-header {
  grid-area: header;
}

.goal-summary {
  grid-area: summary;
}

.goal-list {
  grid-area: list;
}
