@use '../config';
@import 'https://use.typekit.net/slx1xnq.css';

// Horizontal Rules
// ----------------
/// Horizontal rules also provide spacing between sections.
/// @group type
hr {
  margin: var(--spacer) 0;
}

// Selection
// ---------
/// Selected text is inverted,
/// with a hint of the accent hue.
/// @group type
::selection {
  background-color: var(--ccs-accent--fg5);
  color: var(--bg);
}

// Font Loading
// ------------
/// Hide the page visually while fonts are loading,
/// to avoid a flash of unstyled text.
/// @group type
.wf-loading {
  @include config.is-hidden;
}

// Ampersands
// ----------
/// All ampersands default to the fancy baskerville italic.
/// @group type
.amp {
  @include config.font-family('amp');

  font-style: italic; // needed for Safari
}

// H-Cards
// -------
/// By default, non-link/non-org inline h-cards are bold.
/// @group type
span:where(.h-card):not(.p-org) {
  font-weight: bold;
}

// Paragraphs
// ----------
/// Apply spacing after paragraphs
/// @group type
p {
  margin-bottom: var(--newline);
  margin-top: 0;
  text-wrap: pretty;

  &:empty {
    display: none;
  }
}

// Summary
// -------
/// Provide basic interaction styles for detail `summary` elements.
/// @group type
summary {
  cursor: pointer;
  color: var(--open, var(--action));

  &:hover,
  &:focus,
  &:active,
  [open] > & {
    --open: var(--active);
  }
}
