#meta {
  display: grid;
  font-size: var(--small);
  grid-area: meta;
  grid-auto-flow: dense;
  grid-column-gap: var(--gutter);
  grid-template-columns: 1fr;

  > * {
    grid-column: var(--meta-column, 1);
  }

  .subtitle {
    font-size: var(--h3);
  }
}

.post-links {
  --meta-column: 2;

  padding: 0;
  list-style: none;

  li {
    display: inline-block;
  }

  li + li::before {
    content: ' | ';
  }
}
