[data-layout='main'] {
  grid-area: main;
  margin-block-end: var(--spacer);
}

[data-main] {
  margin: var(--gutter) 0;

  > :first-child {
    margin-top: 0;
  }
}

[data-typeset] {
  display: grid;
  grid-template-columns: var(--page-columns);

  > * {
    grid-column: var(--column, page);
  }

  & > & {
    grid-column: full;
  }

  /* stylelint-disable-next-line media-feature-range-notation */
  @media (min-width: 45ch) {
    --inline-bleed: calc(var(--gutter) * -1);
  }
}
