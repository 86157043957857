.oss-badges {
  --list-padding: 0;

  list-style: none;

  li,
  img {
    display: inline;
  }
}
