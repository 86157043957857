@use '../config';

[data-above] {
  display: none;
}

@each $break, $point in config.$breakpoints {
  [data-above='#{$break}'] {
    @include config.above($point) {
      display: revert;
    }
  }
}
