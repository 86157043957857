[data-face] {
  --eyes: hsla(
    var(--ccs-h--neutral),
    var(--ccs-s--bg6),
    var(--ccs-l--bg6),
    var(--ccs-invert--zero)
  );

  overflow: visible;
}

.eyes {
  fill: var(--eyes, black);
}

// combined SVG
[data-nest] {
  transform: translate(var(--x, 0), var(--y, var(--row, 0)));

  &:nth-child(odd) {
    --row: 80px;
  }
}

[data-nest='jonny'] {
  --x: -30px;
}

[data-nest='stacy'] {
  --x: 375px;
}

[data-nest='davidh'] {
  --x: 705px;
}

[data-nest='sondra'] {
  --x: 1025px;
}

[data-nest='miriam'] {
  --x: 1390px;
}

[data-nest='james'] {
  --x: 1770px;
}

[data-nest='ed'] {
  --x: 2090px;
  --y: 100px;
}

[data-nest='victor'] {
  --x: 2400px;
}
