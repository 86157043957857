@use '../config';

[data-layout='banner'] {
  @include config.font-family('alt');

  --banner-space: calc(var(--shim) + 1vw);

  display: grid;
  grid-area: banner;
  grid-template:
    '. logo menu .' minmax(var(--double-gutter), auto)
    '. logo nav .' minmax(var(--double-gutter), auto) / var(--page-margin)
    minmax(5em, 7em)
    1fr var(--page-margin);

  &::before {
    background-color: var(--overlay-banner);
    content: '';
    grid-column: 1 / -1;
    grid-row: menu;
  }
}

#banner-logo {
  &:link,
  &:visited {
    @include config.transition('move');
    @include config.z-index('bump');

    align-self: start;
    display: block;
    grid-area: logo;
    margin-top: var(--half-shim);
    position: relative;
    transform-origin: top center;
  }

  &:hover,
  &:focus,
  &:active {
    transform: scale(1.05);
  }

  [data-logo] {
    height: auto;
    width: 100%;
  }
}
