@use '../config';

/// # Button Pattern
/// @group buttons

// Basic Buttons
// -------------
/// The basic button accepts one CSS adjustment variable --
/// `--btn-padding` --
/// which allows an override of inline padding on the button.
/// @group buttons
[data-btn] {
  @include config.font-family('alt');
  @include config.transition('animate-colors');

  align-items: center;
  background: var(--btn-bg, var(--highlight));
  border: thin solid var(--btn-border, var(--btn-text, transparent));
  border-radius: var(--btn-radius);
  color: var(--btn-text, var(--bg));
  cursor: pointer;
  display: inline-flex;
  font-size: inherit;
  padding: var(--half-shim) var(--btn-padding, var(--double-gutter));

  &:hover,
  &:focus {
    --btn-bg: var(--accent);
    --btn-text: var(--bg);
  }

  &:active,
  &[aria-pressed='true'] {
    --btn-bg: var(--active);
    --btn-text: var(--bg);
  }
}

// Small
// -----
/// "Small" buttons override both the font-size
/// and the inline padding.
/// @group buttons
[data-btn~='small'] {
  --btn-padding: var(--gutter);

  font-size: var(--small);
}

// Small
// -----
/// "xSmall" buttons override both the font-size
/// and the inline padding.
/// @group buttons
[data-btn~='xsmall'] {
  --btn-padding: var(--shim);

  font-size: var(--xsmall);
}

// Menu
// -----
/// @group buttons
[data-btn~='menu'] {
  @include config.font-family('body');

  --btn-bg: transparent;
  --btn-border: var(--border-fade);
  --btn-text: var(--action);
  --marker: var(--btn-text);

  display: list-item;

  [open] & {
    --btn-bg: var(--active);
    --btn-border: var(--btn-bg);
    --btn-text: var(--bg);
  }
}
