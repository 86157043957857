@use '../config';

/// # Heading Defaults
/// Initial global defaults for heading elements…
/// @group headings

// All headings
// ------------
/// all headings have bottom-margin removed
/// and heading-links inherit the heading color
/// @group headings
h1,
h2,
h3,
h4,
h5,
h6 {
  @include config.font-family('alt');

  --link: currentcolor;

  margin-bottom: 0;
  text-wrap: balance;
}

h1,
h2 {
  line-height: 1.2;
  margin: var(--gutter-plus) 0 var(--newline);
}

h2,
h3 {
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    background: linear-gradient(
      to bottom right,
      var(--heading-start),
      var(--heading-end)
    );
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    max-width: max-content;
  }
}

h3,
h4 {
  margin-top: var(--newline);
}

h1 {
  font-size: var(--h1);
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
}
