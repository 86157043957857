@use '../config';

$break: 'wide-break';

.offset-grid {
  --column: full;

  display: grid;
  grid-gap: var(--double-gutter);
  margin: var(--spacer) 0;
  position: relative;

  @include config.above($break) {
    --clip-overlap: calc(var(--gutter) * -1);
    --offset: var(--spacer);

    grid-template: var(--offset) / repeat(2, 1fr);

    // provide space for the right-hand vertical offset
    &::before {
      content: '';
      grid-column: span 2;
    }
  }
}

.offset-item {
  @include config.clip-base;

  &:nth-child(odd) {
    @include config.clip('right', $break);
  }

  &:nth-child(even) {
    @include config.clip('left', $break);
  }
}

.offset-more {
  padding: 0 var(--page-margin);

  @include config.above($break) {
    inset: auto auto 0 50%;
    padding-left: 0;
    position: absolute;
  }
}

// services page

.offset-media {
  --offset-media-padding: 0;

  display: flex;

  img {
    flex: 1 0 auto;
    height: 100%; // Safari fix
    object-fit: cover;
  }
}
