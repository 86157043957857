@use '../config';

[data-post] {
  --post-margin: var(--gutter);
  --fold: var(--triple-gutter);
  --drop: var(--half-shim);
  --drop-focus: var(--shim);
  --left: calc(var(--drop-focus) * -1);
  --bottom: calc(100% + var(--drop-focus));

  clip-path: polygon(
    var(--left) 0,
    calc(100% - var(--fold)) 0,
    100% var(--fold),
    100% var(--bottom),
    var(--left) var(--bottom)
  );
  display: grid;
  grid-area: post;
  grid-row-gap: var(--shim);
  grid-template:
    [hero-start] 'left type type' var(
      --post-thumb-height,
      var(--post-thumb-small)
    )
    [hero-end] 'left main right' 1fr
    / [hero-start] var(--post-margin) minmax(min-content, var(--page)) var(
      --post-margin
    )
    [hero-end];

  .post-header {
    margin-bottom: var(--gutter);
  }
}

[data-post='link'] {
  @include config.transition('pop');

  --hero-height: var(--grid-hero);
  --item-title: 1rem;
  --newline: var(--half-shim);

  background: var(--card, var(--callout));
  box-shadow: calc(var(--drop) * -1) var(--drop) 0 var(--pop, var(--shadow));
  position: relative;

  &:hover,
  &:focus-within {
    --img-bright: 1;
    --img-contrast: 1;
    --drop: var(--drop-focus);
    --pop: var(--active);

    transition-delay: 20ms;
  }

  .title-link {
    --underline-color: transparent;

    display: block;

    &::after {
      @include config.z-index('bump');

      content: '';
      display: block;
      inset: 0;
      position: absolute;
    }
  }
}

[data-post='large'] {
  --item-title: var(--h2);
  --post-margin: minmax(0, 1fr);
  --post-thumb-height: auto;
}

// Elements
// --------

.type-flag {
  @include config.font-family('alt');

  background: var(--highlight);
  color: var(--bg);
  font-size: var(--xsmall);
  grid-area: type;
  place-self: start end;
  margin: var(--half-shim) var(--quarter-shim);
  padding-right: var(--fold);
  text-transform: uppercase;

  &:link,
  &:visited {
    --fill: var(--bg);

    text-decoration: none;
    color: var(--bg);
  }

  &[href]:hover,
  &[href]:focus,
  &[href]:active {
    background: var(--feature);
  }

  [data-icon] {
    margin-left: var(--half-shim);
  }
}

.post-content {
  grid-area: main;
  padding-bottom: var(--gutter);
}

.post-header {
  --newline: 0;
  --span-display: inline-block;
}

.subtitle {
  font-style: italic;
}

.byline {
  font-size: var(--small);

  time,
  span {
    display: var(--span-display, initial);
  }
}

.summary {
  margin: var(--gutter) 0;
}

.item-timing {
  font-size: var(--small);
}
