@use '../config';

[data-layout='header'] {
  display: grid;

  .type-flag {
    @include config.z-index('bump');

    margin: var(--gutter) 0 var(--shim) auto;
    padding-block: var(--quarter-shim);
    padding-right: var(--gutter);
  }
}

[data-header='hero'] {
  --angle: var(--edge);
  --edge: calc(var(--page-margin) / 2);
  --clip-overlap: var(--gutter);

  grid-template:
    'hero type type' auto
    'title title hero2' var(--clip-overlap)
    'title title .' auto
    'meta meta meta' auto
    'note note note' auto
    'intro intro intro' auto
    / auto auto minmax(var(--edge), 1fr);

  #title {
    padding-left: var(--page-margin);
    padding-top: var(--half-shim);
  }

  #meta {
    padding: 0 var(--page-margin);
  }

  @media (width >= 35em) and (height >= 35em) {
    --clip-overlap: var(--h1);
  }

  @include config.above(35em) {
    --angle: var(--page-margin);
    --edge: var(--page-margin);
  }

  @include config.above(50em) {
    --edge: 25%;
  }
}

[data-header='angle'] {
  display: grid;
  grid-template-columns: var(--page-columns);
  margin: var(--spacer) 0;
  place-items: center;

  > * {
    grid-column: var(--column, page);
  }

  .type-flag {
    grid-area: 1 / 1 / span 1 / -1;
  }
}

[data-header~='stacked'] {
  line-height: 1;
  margin-block: var(--stacked-margin-block, var(--newline));

  .title-action,
  .title-description {
    display: block;
    font-size: var(--h3);
    font-weight: normal;
  }

  .title-action {
    margin-block-end: var(--title-action-margin-block-end, 0);
  }

  .title-description {
    @include config.font-family('body');

    font-style: italic;
  }
}
