/// # Replaced Media Defaults
/// @group init-media

// Replaced Elements
// -----------------
/// Replaced media elements get a new-line of spacing after.
/// @group init-media
audio,
canvas,
iframe,
video {
  margin-bottom: var(--newline);
}

// Image brightness
// ----------------
/// Adjust image brightness depending on mode & contrast
/// @group init-media
img {
  filter: brightness(var(--img-bright, var(--img-bright--default, 1)))
    contrast(var(--img-contrast, var(--img-contrast--default, 1)));
}

// Picture
// -------
/// The picture element shouldn't be part of the DOM tree
/// @group init-media
picture {
  display: contents;
}

// Source
// -------
/// The source element is never shown
/// @group init-media
source {
  display: none;
}

// Audio
// -----
/// Audio is full-width by default.
/// @group init-media
audio[controls] {
  filter: drop-shadow(0 0 var(--half-shim) var(--shadow));
  width: 100%;
}

// SVG defaults
// ------------
/// Unless otherwise defined,
/// embedded SVGs will take on the color
/// of surrounding content.
/// Adding `1px` padding helps with overflow clipping errors
/// on rounded paths.
/// @group init-media
svg {
  fill: var(--fill, currentColor);
  padding: 1px;
}
