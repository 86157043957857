/// # Range Input Tools
/// Provide browser-prefixed selectors for styling
/// range input tracks and thumbs.
/// @group tools-range

// Track
// -----
/// Generate browser-prefixed selector blocks
/// for styling range input 'tracks'.
/// This works like a selector,
/// with content rather than arguments.
/// @content
///   The styles to apply to range input tracks
/// @example scss
///   @use 'tools/input-range' as range;
///
///   .track {
///     @include range.track {
///       appearance: none;
///       height: 1em;
///       cursor: pointer;
///     }
///   }
/// @group tools-range
@mixin track {
  &::-webkit-slider-runnable-track {
    @content;
  }

  &::-moz-range-track {
    @content;
  }

  &::-ms-track {
    @content;
  }
}

// Thumb
// -----
/// Generate browser-prefixed selector blocks
/// for styling range input 'thumbs' --
/// the handle that can be moved along a track.
/// This works like a selector,
/// with content rather than arguments.
/// @content
///   The styles to apply to range input thumbs
/// @example scss
///   @use 'tools/input-range' as range;
///
///   .thumb {
///     @include range.thumb {
///       appearance: none;
///       height: 1em;
///       width: 0.5em;
///       background: var(--ccs-prime);
///       cursor: pointer;
///     }
///   }
/// @group tools-range
@mixin thumb {
  &::-webkit-slider-thumb {
    @content;
  }

  &::-moz-range-thumb {
    @content;
  }

  &::-ms-thumb {
    @content;
  }
}
