@use '../config';

// Fields
// ------
/// Form fields (using the `label` for markup)
/// are set to block display, with a margin.
/// @group forms
[data-field],
[data-fieldset] {
  display: var(--ccs-custom-display, block);
  margin-bottom: var(--shim);
}

// Labels
// ------
/// Form label text uses a span inside the wrapper label
/// and is displayed above the input.
/// @group forms
[data-label] {
  @include config.font-family('alt');

  display: block;
}

// Text Inputs
// -----------
/// Varioius input "types" all rely on the same basic
/// text-input style.
/// @group forms
[data-input*='text'] {
  --focus-ring: transparent;

  background-color: var(--bg-full);
  border: thin solid var(--input-border, var(--border));
  border-radius: 0;
  color: var(--text);
  display: block;
  font: inherit;
  font-size: var(--xsmall);
  padding: var(--half-shim);
  width: 100%;

  &:focus {
    border-color: var(--input-border-focus, var(--accent));
  }
}

// Form Actions
// ------------
/// A wrapper element to provide layout and spacing
/// of the actions at the end of a form.
/// @group forms
.form-actions {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  gap: var(--newline);
  margin-top: var(--newline);

  p {
    margin: unset;
  }
}

// Honey
// -----
/// Apply to honey-pot SPAM-protection fields…
/// @group forms
[data-honey] {
  @include config.is-hidden;
}
