/* stylelint-disable scss/operator-no-newline-after */

@use '../config';

$two-column: 45em;

[data-layout='footer'] {
  @include config.font-family('alt');

  display: grid;
  font-size: var(--small);
  grid-area: footer;
  grid-gap: var(--page-margin);
  grid-template: var(
    --footer-template-sm,
    'contact' min-content 'oddnews' min-content 'contentinfo' auto / 100%
  );
  padding: var(--gutter) var(--page-margin) var(--spacer);

  @include config.above($two-column) {
    grid-template: var(
      --footer-template-lg,
      'contact oddnews' min-content 'contact contentinfo' 1fr /
        minmax(10em, var(--wide-page)) minmax(10em, var(--page))
    );
    justify-content: space-between;
  }
}

[aria-label='contact'] {
  grid-area: contact;
}

[role='contentinfo'] {
  --link: currentcolor;
  --underline-color: currentcolor;

  display: grid;
  grid-area: contentinfo;
  grid-gap: var(--gutter);
  grid-template-rows: 1fr auto;

  @include config.above($two-column) {
    text-align: var(--footer-text-align, right);
  }

  &:only-child {
    grid-column: 1 / -1;
  }

  a {
    display: inline-block;
    font-weight: bold;
  }
}

.legal {
  --newline: 0;

  font-size: var(--xsmall);
}

// Layout variation for contact page
[data-footer~='contact'] {
  --footer-template-lg: 'contentinfo oddnews' auto / 1fr 1fr;
  --footer-text-align: left;
}
