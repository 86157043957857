@use 'tools' as *;
@use 'sass:map';
@use 'sass:meta';
@use 'scale/page-sizes';

/// List of named z-index layers.
/// The order they are defined will be the integer they are assigned.
/// @group config-layout
$z-index: ('bump', 'dropdown', 'skip-link');

@mixin clip-base {
  align-content: center;
  background-color: var(--clip-bg, var(--callout));
  display: grid;
  margin: 0;
  padding: var(--offset-media-padding, var(--spacer)) 0;
}

@mixin clip($side, $break: 'wide-break') {
  clip-path: var(--clip-#{$side});
  margin-#{$side}: calc(var(--clip-overlap) * -0.5);
  padding-#{$side}: var(--offset-media-padding, var(--clip-space));

  @if ($side == 'left') {
    transform: translateY(calc(var(--offset) * -1));
  }

  @include above($break) {
    margin-#{$side}: var(--clip-overlap);
  }
}

// Breakpoints
@each $name, $size in meta.module-variables('page-sizes') {
  $size: convert-units($size, 'em');
  $breakpoints: map.merge(
    $breakpoints,
    (
      $name: $size,
    )
  );
}
