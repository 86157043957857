@use '../config';

// OddNews forms
// -------------
.oddnews-signup {
  background-color: var(--oddnews-bg, var(--clip-bg, var(--callout)));
  padding: 0 var(--gutter) var(--gutter);
}

// OddNews form in main content area on OddNews archive page
.oddnews-form-main {
  --oddnews-bg: transparent;
}

// OddNews form in footer
.oddnews-form-footer {
  grid-area: oddnews;
}

// OddNews "What to Expect"
// ------------------------
// layout override of CTA macro
.oddnews-intro {
  --cta-grid-area: auto / full-start / auto / full-end;
}

// OddNews archive link list from Mailchimp
// ----------------------------------------
.campaign {
  display: grid;
  margin-bottom: var(--gutter-plus);

  @include config.above('wide-page') {
    grid-template-columns: 1fr 3fr;
  }
}
